import React, { useReducer, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { FaPlay } from "react-icons/fa";
import PlayerControls from "./PlayerControls";
import PlayerOverlay from "./PlayerOverlay";
import { INITIAL_STATE, reducer } from "./Player.reducer";

const Player = (props) => {
  const { movie } = props;
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const playerRef = useRef(null);
  const wrapperRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);

  const handlePreview = () => {
    dispatch({ type: "PLAY" });
    dispatch({ type: "LIGHT", payload: false });
  };

  const handlePause = () => {
    dispatch({ type: "PAUSE" });
  };

  const handlePlay = () => {
    dispatch({ type: "PLAY" });
  };

  const handleEnded = () => {
    dispatch({ type: "LIGHT", payload: true });
    playerRef.current?.showPreview();
  };

  const handleProgress = (progress) => {
    dispatch({ type: "SEEK", payload: progress.playedSeconds });
  };

  const handleDuration = (duration) => {
    dispatch({ type: "DURATION", payload: duration });
  };

  const styles = {
    playerWrapper: {
      position: "relative",
      aspectRatio: "16 / 9",
      borderRadius: "8px",
      overflow: "hidden",
    },
    video: {
      borderRadius: "8px",
    },
    previewOverlay: {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "linear-gradient(to top, rgba(0, 0, 0, 0.1), transparent)",
    },
  };

  return (
    <div
      style={styles.playerWrapper}
      className={`player-wrapper ${state.light ? "light" : ""} ${
        state.playing ? "playing" : "paused"
      }`}
      ref={wrapperRef}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <ReactPlayer
        ref={playerRef}
        url={movie?.videoUrl}
        width="100%"
        height="100%"
        light={movie?.imageUrl}
        playIcon={<FaPlay />}
        controls={state.controls}
        loop={state.loop}
        muted={state.muted}
        playing={state.playing}
        playbackRate={state.playbackRate}
        volume={state.volume}
        onPlay={handlePlay}
        onEnded={handleEnded}
        onPause={handlePause}
        onDuration={handleDuration}
        onProgress={handleProgress}
        onClickPreview={handlePreview}
      />
      <PlayerOverlay state={state} movie={movie} />
      {!state.controls && !state.light && (!state.playing || isHovered) && (
        <PlayerControls
          state={state}
          dispatch={dispatch}
          playerRef={playerRef}
          wrapperRef={wrapperRef}
        />
      )}
    </div>
  );
};

export default Player;
