import React from "react";
import {
  FaPlay,
  FaPause,
  FaVolumeUp,
  FaVolumeDown,
  FaExpand,
} from "react-icons/fa";
import screenfull from "screenfull";
import { findDOMNode } from "react-dom";

const PlayerControls = ({ state, dispatch, wrapperRef, playerRef }) => {
  // Inline CSS
  const styles = {
    controls: {
      position: "absolute",
      padding: "10px",
      boxSizing: "border-box",
      bottom: 0,
      left: 0,
      width: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      zIndex: 1,
      borderBottomLeftRadius: "8px",
      borderBottomRightRadius: "8px",
      transition: "opacity 0.2s ease-in-out",
    },
    range: {
      appearance: "none",
      width: "100%",
      background: "#fff",
      height: "4px",
      borderRadius: "2px",
      outline: "none",
      cursor: "pointer",
    },
    volumeControl: {
      display: "flex",
      alignItems: "center",
      gap: "8px",
    },
    timeText: {
      color: "white",
      fontSize: "14px",
    },
    button: {
      background: "none",
      border: "none",
      cursor: "pointer",
      color: "white",
    },
  };

  const handleSound = (event) => {
    dispatch({ type: "VOLUME", payload: parseFloat(event.target.value) });
  };

  const handleFullscreen = () => {
    screenfull.toggle(findDOMNode(wrapperRef.current));
  };

  const handleSeek = (event) => {
    playerRef.current.seekTo(parseFloat(event.target.value));
  };

  const renderSeekSlider = () => {
    return (
      <input
        type="range"
        min={0}
        max={state.duration}
        step={0.01}
        value={state.progress.playedSeconds}
        onChange={handleSeek}
        style={{
          ...styles.range,
          background: `linear-gradient(to right, #0076ff ${
            (state.progress.playedSeconds / state.duration) * 100
          }%, #adadad 0%)`,
        }}
      />
    );
  };

  const renderPlayButton = () => {
    return (
      <button
        onClick={() => dispatch({ type: "TOGGLE_PLAY" })}
        style={styles.button}
      >
        {state.playing ? <FaPause size={24} /> : <FaPlay size={24} />}
      </button>
    );
  };

  const renderSoundSlider = () => {
    return (
      <div style={styles.volumeControl}>
        <FaVolumeDown size={16} />
        <input
          type="range"
          min={0}
          max={1}
          step={0.01}
          value={state.volume}
          onChange={handleSound}
          style={{
            ...styles.range,
            background: `linear-gradient(to right, #0076ff ${
              (state.volume / 1) * 100
            }%, #adadad 0%)`,
          }}
        />
        <FaVolumeUp size={16} />
      </div>
    );
  };

  const renderDurationText = () => {
    const formatTime = (seconds) => {
      const minutes = Math.floor(seconds / 60)
        .toString()
        .padStart(2, "0");
      const secs = Math.floor(seconds % 60)
        .toString()
        .padStart(2, "0");
      return `${minutes}:${secs}`;
    };

    return (
      <span style={styles.timeText}>
        {formatTime(state.progress.playedSeconds)} /{" "}
        {formatTime(state.duration)}
      </span>
    );
  };

  const renderFullscreenButton = () => {
    return (
      <button onClick={handleFullscreen} style={styles.button}>
        <FaExpand size={24} />
      </button>
    );
  };

  return (
    <div style={styles.controls}>
      <div>{renderSeekSlider()}</div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          {renderPlayButton()} {renderSoundSlider()} {renderDurationText()}
        </div>
        <div>{renderFullscreenButton()}</div>
      </div>
    </div>
  );
};

export default PlayerControls;
