import { Fragment, useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchepisode } from "../redux/slice/Movie";
import Footer from "../components/Footer";
import Loader from "../components/UI/Loader";
import ErrorMessage from "../components/UI/ErrorMEssage";
import Player from "../components/player/Player";

export default function Movie() {
  // get template id from url
  const { id } = useParams();
  const dispatch = useDispatch();

  const { movie, error, loading } = useSelector((state) => state.movie);

  useEffect(() => {
    dispatch(fetchepisode({ templateID: id }));
  }, []);
  // useEffect(() => {
  //   let timeOut = setTimeout()
  //   if (constrollsVisible === "active"){
  //   }

  // }, [constrollsVisible])

  if (loading) {
    return <Loader message="loading your episode" />;
  }

  if (error) {
    return <ErrorMessage message={error} />;
  }
  return (
    <div className="w-full flex gap-2 flex-col pb-8 pt-4 h-full max-h-full max-w-[56rem] mx-auto overflow-hidden box-border">
      <header className="py-4 px-4 flex items-center gap-4 justify-center">
        <img src="/logo2.png" className="size-10" />
        <span className="text-2xl">Zingroll Movies</span>
      </header>
      <Player movie={movie} />
      <Footer />
    </div>
  );
}
