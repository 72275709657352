import React from "react";

const PlayerOverlay = ({ state, movie }) => {
  // Inline CSS
  const styles = {
    overlay: {
      position: "absolute",
      width: "100%",
      boxSizing: "border-box",
      pointerEvents: "none",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      left: 0,
      top: 0,
      bottom: state.light ? "0" : "84px",
      backgroundColor:
        state.light || state.playing ? "transparent" : "rgba(0, 0, 0, 0.4)",
      opacity: state.playing ? "0" : "1",
      transition: "opacity 0.2s ease-in-out",
      borderTopLeftRadius: "8px",
      borderTopRightRadius: "8px",
    },
    overlayInner: {
      paddingLeft: state.light ? "30px" : "25px",
      paddingBottom: state.light ? "100px" : "8px",
      width: state.light ? "auto" : "100%",
    },
    chip: {
      display: "inline-block",
      padding: "4px 8px",
      fontSize: "14px",
      fontWeight: "bold",
      color: "#fff",
      backgroundColor: "#f57c00", // warning color
      borderRadius: "16px",
      marginBottom: "8px",
    },
    title: {
      fontSize: "32px",
      color: "white",
      margin: "1px 0",
    },
    year: {
      fontSize: "12px",
      color: "white",
      textTransform: "uppercase",
    },
  };

  return (
    <div style={styles.overlay}>
      <div style={styles.overlayInner}>
        <span style={styles.chip}>#1 in series</span>
        <h4 style={styles.title}>{movie?.name}</h4>
        <span style={styles.year}>{movie?.title}</span>
      </div>
    </div>
  );
};

export default PlayerOverlay;
