import { Fragment } from "react";
import Countdown from "../components/Counter";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
function Home() {
  return (
    <div className="w-full h-full flex items-center flex-col justify-center">
      <Fragment>
          <img src="/logo2.png" className="h-[300px]" />
          {/* <Countdown /> */}
          <Footer />
        </Fragment>
      {/* {process.env.NODE_ENV === "development" ? (
        <div className="grid grid-cols-2 gap-4">
          <Link to="/invite/a7403d30-0e32-4ab4-82e2-3643d379c0fa" className="bg-white text-center text-black px-4 py-2 rounded-md">
            {"invite link"}
          </Link>
          <Link to="/share/38f94085-d480-492f-8364-6d02ca81d917" className="bg-white text-center text-black px-4 py-2 rounded-md">
            {"movie link"}
          </Link>
          <Link to="/terms"  className="bg-white text-center text-black px-4 py-2 rounded-md">
            {"Terms"}
          </Link>
          <Link to="/privacy" className="bg-white text-center text-black px-4 py-2 rounded-md">
            {"Privacy"}
          </Link>
        </div>
      ) : (
        null
      )} */}
    </div>
  );
}

export default Home;
